var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-1" },
        [
          _c(
            "b-form",
            {
              ref: "formFilter",
              staticClass: "m-0",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.getData()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Data de Emissão")]),
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            attrs: {
                              config: {
                                mode: "range",
                                altInput: true,
                                dateFormat: "Y-m-d",
                                altFormat: "d/m/Y",
                                locale: "pt",
                              },
                            },
                            model: {
                              value: _vm.filters.billingAtDateRange,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "billingAtDateRange", $$v)
                              },
                              expression: "filters.billingAtDateRange",
                            },
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", { staticClass: "bi bi-calendar-range" }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "3" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Data de Vencimento")]),
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            attrs: {
                              config: {
                                wrap: true,
                                mode: "range",
                                altInput: true,
                                dateFormat: "Y-m-d",
                                altFormat: "d/m/Y",
                                locale: "pt",
                              },
                            },
                            model: {
                              value: _vm.filters.dueAtDateRange,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "dueAtDateRange", $$v)
                              },
                              expression: "filters.dueAtDateRange",
                            },
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _c("i", { staticClass: "bi bi-calendar-range" }),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Status")]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "label",
                              multiple: "",
                              reduce: function (status) {
                                return status.value
                              },
                              options: _vm.optionsStatus,
                            },
                            model: {
                              value: _vm.filters.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "status", $$v)
                              },
                              expression: "filters.status",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v("Nenhum registro encontrado")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Método de Pagamento")]),
                        _c(
                          "v-select",
                          {
                            attrs: {
                              label: "name",
                              options: _vm.optionsPaymentMethods,
                              reduce: function (payment) {
                                return payment.id
                              },
                            },
                            model: {
                              value: _vm.filters.payment_method,
                              callback: function ($$v) {
                                _vm.$set(_vm.filters, "payment_method", $$v)
                              },
                              expression: "filters.payment_method",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "no-options" },
                                slot: "no-options",
                              },
                              [_vm._v("Nenhum registro encontrado")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Quantidade de Parcelas")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.installments,
                            expression: "filters.installments",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.installments },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.filters,
                              "installments",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("ID do Cliente")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.customer_id,
                            expression: "filters.customer_id",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.customer_id },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.filters,
                              "customer_id",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Código do Cliente")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.clientCode,
                            expression: "filters.clientCode",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.clientCode },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.filters,
                              "clientCode",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("ID da Fatura")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.invoiceId,
                            expression: "filters.invoiceId",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.invoiceId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.filters,
                              "invoiceId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("b-col", { attrs: { cols: "12", md: "2" } }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Código da Fatura")]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filters.invoiceCode,
                            expression: "filters.invoiceCode",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel" },
                        domProps: { value: _vm.filters.invoiceCode },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.filters,
                              "invoiceCode",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "offset-md-10",
                      attrs: { cols: "12", md: "2" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-block mt-3",
                          attrs: { type: "submit" },
                        },
                        [
                          _vm.loading
                            ? _c(
                                "div",
                                [
                                  _c("b-spinner", {
                                    attrs: { label: "Loading...", small: "" },
                                  }),
                                ],
                                1
                              )
                            : _c("div", [_vm._v("Aplicar Filtros")]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-form-row",
                { staticClass: "justify-content-between" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-none d-sm-block align-items-center justify-content-left",
                      attrs: { cols: "4", md: "2" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          required: "",
                          options: _vm.perPageOptions,
                          clearable: false,
                        },
                        model: {
                          value: _vm.filters.perPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "perPage", $$v)
                          },
                          expression: "filters.perPage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "4", offset: "md-4" } },
                    [
                      _c("b-form-input", {
                        staticClass: "d-inline-block",
                        attrs: {
                          type: "search",
                          placeholder: "Pesquisar...",
                          id: "filterInput",
                        },
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              items: _vm.dataItems,
              busy: _vm.loading,
              responsive: "",
              fields: _vm.fields,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(details)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "xs" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleDetails(data.index)
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: data.item._showDetails ? "dash" : "plus",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(due_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(data.item.due_at)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(data.item.created_at)) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(amount)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("toCurrency")(Number(data.item.amount))) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        attrs: {
                          variant: _vm.getStatusVariant(data.item.status),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getStatusLabel(data.item.status)) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (data) {
                  return _vm._l(data.item.charges, function (item) {
                    return _c("div", { key: item.id, staticClass: "ml-5" }, [
                      _c("p", [
                        _c("strong", [_vm._v("ID do Cliente:")]),
                        _vm._v(" " + _vm._s(data.item.customer.id)),
                      ]),
                      _c("p", [
                        _c("strong", [_vm._v("Método de pagamento:")]),
                        _vm._v(" " + _vm._s(item.payment_method.name) + " "),
                      ]),
                      _c("p", [
                        _c("strong", [_vm._v("Quantidade de parcelas:")]),
                        _vm._v(" " + _vm._s(item.installments) + " "),
                      ]),
                    ])
                  })
                },
              },
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "p",
                        { staticClass: "my-0 py-3 d-flex-center" },
                        [
                          _c("b-spinner", {
                            staticClass: "mr-50",
                            attrs: { small: "", variant: "primary" },
                          }),
                          _vm._v(" Buscando pedidos... "),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": 10000,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }