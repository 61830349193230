<template>
  <div>
    <b-card class="mb-1">
      <b-form class="m-0" ref="formFilter" @submit.prevent="getData()">
        <!-- Priemira Linha de Filtros -->
        <div class="form-row">
          <b-col cols="12" md="3">
            <div class="form-group">
              <label>Data de Emissão</label>

              <div class="input-group">
                <flat-pickr
                  v-model="filters.billingAtDateRange"
                  class="form-control"
                  :config="{
                    mode: 'range',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="bi bi-calendar-range"></i>
                  </span>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="3">
            <div class="form-group">
              <label>Data de Vencimento</label>
              <div class="input-group">
                <flat-pickr
                  v-model="filters.dueAtDateRange"
                  class="form-control"
                  :config="{
                    wrap: true,
                    mode: 'range',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    locale: 'pt',
                  }"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="bi bi-calendar-range"></i>
                  </span>
                </div>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="2">
            <div class="form-group">
              <label>Status</label>
              <v-select
                label="label"
                multiple
                :reduce="(status) => status.value"
                :options="optionsStatus"
                v-model="filters.status"
              >
                <span slot="no-options">Nenhum registro encontrado</span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" md="2">
            <div class="form-group">
              <label>Método de Pagamento</label>
              <v-select
                label="name"
                :options="optionsPaymentMethods"
                :reduce="(payment) => payment.id"
                v-model="filters.payment_method"
              >
                <span slot="no-options">Nenhum registro encontrado</span>
              </v-select>
            </div>
          </b-col>

          <b-col cols="12" md="2">
            <div class="form-group">
              <label>Quantidade de Parcelas</label>
              <input
                type="tel"
                v-model="filters.installments"
                class="form-control"
              />
            </div>
          </b-col>
        </div>

        <!-- Segunda Linha de Filtros -->
        <div class="form-row">
          <b-col cols="12" md="2">
            <div class="form-group">
              <label>ID do Cliente</label>
              <input
                type="tel"
                v-model="filters.customer_id"
                class="form-control"
              />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="form-group">
              <label>Código do Cliente</label>
              <input
                type="tel"
                v-model="filters.clientCode"
                class="form-control"
              />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="form-group">
              <label>ID da Fatura</label>
              <input
                type="tel"
                v-model="filters.invoiceId"
                class="form-control"
              />
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="form-group">
              <label>Código da Fatura</label>
              <input
                type="tel"
                v-model="filters.invoiceCode"
                class="form-control"
              />
            </div>
          </b-col>
        </div>

        <!-- Botão de Aplicar Filtros -->
        <div class="form-row">
          <b-col cols="12" md="2" class="offset-md-10">
            <button type="submit" class="btn btn-primary btn-block mt-3">
              <div v-if="loading">
                <b-spinner label="Loading..." small />
              </div>
              <div v-else>Aplicar Filtros</div>
            </button>
          </b-col>
        </div>
      </b-form>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-form-row class="justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              v-model="filter"
            />
          </b-col>
        </b-form-row>
      </div>

      <b-table
        class="position-relative"
        :items="dataItems"
        :busy="loading"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(details)="data">
          <div>
            <b-button size="xs" @click="toggleDetails(data.index)">
              <b-icon :icon="data.item._showDetails ? 'dash' : 'plus'"></b-icon>
            </b-button>
          </div>
        </template>

        <template #cell(due_at)="data">
          {{ formatDate(data.item.due_at) }}
        </template>
        <template #cell(created_at)="data">
          {{ formatDate(data.item.created_at) }}
        </template>
        <template #cell(amount)="data">
          {{ Number(data.item.amount) | toCurrency }}
        </template>
        <template #cell(status)="data">
          <b-badge
            class="d-block"
            :variant="getStatusVariant(data.item.status)"
          >
            {{ getStatusLabel(data.item.status) }}
          </b-badge>
        </template>

        <template #row-details="data">
          <div v-for="item in data.item.charges" :key="item.id" class="ml-5">
            <p><strong>ID do Cliente:</strong> {{ data.item.customer.id }}</p>
            <p>
              <strong>Método de pagamento:</strong>
              {{ item.payment_method.name }}
            </p>
            <p>
              <strong>Quantidade de parcelas:</strong>
              {{ item.installments }}
            </p>
          </div>
        </template>

        <template #table-busy>
          <div class="text-center">
            <p class="my-0 py-3 d-flex-center">
              <b-spinner small variant="primary" class="mr-50" /> Buscando
              pedidos...
            </p>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="10000"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BTooltip,
  BBadge,
  BFormRow,
  BSpinner,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import { formatDate } from "@fullcalendar/core";

export default {
  components: {
    flatPickr,
    CustomPaginateTable,
    BCard,
    BFormRow,
    BForm,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BButton,
    BPagination,
    BMedia,
    BTooltip,
    BBadge,
    BFormGroup,
    BPagination,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      filters: {
        currentPage: 1,
        perPage: 25,
      },
      loading: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      currentPage: 1,
      fields: [
        { key: "details", label: "", class: "text-center" },
        {
          key: "id",
          label: "ID",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Emissão",
          class: "text-center",
        },
        {
          key: "due_at",
          label: "Vencimento",
          class: "text-center",
        },
        {
          key: "customer.name",
          label: "Cliente",
          class: "text-center",
        },
        {
          key: "customer.email",
          label: "email",
          class: "text-center",
        },
        {
          key: "amount",
          label: "Valor",
          class: "text-left",
        },
        {
          key: "installments",
          label: "Parcelas",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
        },
      ],
      chargeFields: [
        { key: "id", label: "ID da Cobrança", class: "text-center" },
        { key: "due_at", label: "Data de Vencimento", class: "text-center" },
        { key: "amount", label: "Valor", class: "text-center" },
        {
          key: "payment_method",
          label: "Método de Pagamento",
          class: "text-center",
        },
        { key: "installments", label: "Parcelas", class: "text-center" },
      ],
      itemsPerPage: 10, // Quantidade padrão de itens por página
      totalItems: 10000,
      perPageOptions: [25, 50, 100],
      optionsPaymentMethods: [],
      optionsStatus: [
        {
          value: "review",
          label: "Revisão",
        },
        {
          value: "pending",
          label: "Pendente",
        },
        {
          value: "paid",
          label: "Pago",
        },
        {
          value: "canceled",
          label: "Cancelado",
        },
        {
          value: "scheduled",
          label: "Agendado",
        },
      ],
      optionsProducts: [],
      optionsProductCategories: [],
    };
  },
  methods: {
    async fetchPaymentMethods() {
      this.$store.dispatch("Order/paymentMethods").then((res) => {
        this.optionsPaymentMethods = res;
      });
    },
    submitFilter() {
      this.getData();
    },
    formatDate(date) {
      const formattedDate = moment(date);
      return formattedDate.isValid()
        ? formattedDate.format("DD/MM/YYYY HH:mm:ss")
        : "";
    },
    toggleDetails(index) {
      this.dataItems[index]._showDetails = !this.dataItems[index]._showDetails;
    },
    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.filters.currentPage = 1;
    },
    async getPaymentMethods() {
      this.$store.dispatch("Vindi/paymentMethods").then((res) => {
        this.optionsPaymentMethods = res;
      });
    },
    getStatusLabel(status) {
      const option = this.optionsStatus.find(
        (option) => option.value === status
      );
      return option ? option.label : "Desconhecido";
    },
    getStatusVariant(status) {
      switch (status) {
        case "review":
          return "warning";
        case "pending":
          return "danger";
        case "paid":
          return "success";
        case "canceled":
          return "secondary";
        case "scheduled":
          return "primary";
        default:
          return "dark";
      }
    },
    async getData() {
      this.loading = true;

      if (this.filters.dueAtDateRange) {
        const split = this.filters.dueAtDateRange.split(" até ");
        this.filters.dueAt = {
          start: split[0],
          end: split[1],
        };
      }

      if (this.filters.billingAtDateRange) {
        const split = this.filters.billingAtDateRange.split(" até ");
        this.filters.billingAt = {
          start: split[0],
          end: split[1],
        };
      }

      try {
        const bilss = await this.$store.dispatch("Vindi/bills", {
          ...this.filters,
        });

        if (bilss) {
          this.dataItems = bilss.map((bill) => {
            bill._showDetails = false;
            return bill;
          });

          if (bilss.length < this.itemsPerPage) {
            this.totalItems =
              (this.currentPage - 1) * this.itemsPerPage + bilss.length;
          } else {
            this.totalItems = this.currentPage * this.itemsPerPage + 1; // Isso faz a paginação continuar até o último page
          }
        }
      } catch (error) {
        this.dataItems = [];
      } finally {
        this.loading = false;
      }
    },
  },
  directives: {
    Ripple,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
    this.$root.$off("open:export-table");
  },
  async mounted() {
    this.loading = true;
    this.filters.currentPage = 1;
    this.filters.perPage = 25;

    await this.getPaymentMethods();
    await this.getData();

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.swal2-icon-content {
  i {
    font-size: 2rem;
    color: #28c76f;
  }
}

.swal2-icon-show {
  border-color: #28c76f;
}
</style>
